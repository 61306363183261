<template>
  <div class="detail">
    <detail-header title="编辑服务" />
    <div class="detail_main page_width">
      <div class="main_content" v-loading="loading">
        <!-- 自定义表单 -->
        <custom-form
          ref="customForm"
          mode="enter"
          :formModel="fieldList"
          :formData="formData">
          <custom-form-item
            v-for="item in fieldList"
            :key="item.column"
            :item="item"
            :value.sync="formData[item.column]">
          </custom-form-item>
        </custom-form>

        <div class="btn_box">
          <el-button
            type="primary"
            size="small"
            :loading="btnLoading"
            @click="submitForm">
            保存
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import customForm from '@/components/custom-form/index'
import customFormItem from '@/components/custom-form/custom-form-item'
export default {
  components: {
    customForm,
    customFormItem
  },
  data () {
    return {
      loading: false,
      // 提交按钮的Loading
      btnLoading: false,
      // 服务类型
      serviceTypeId: '',
      // 字段数组
      fieldList: [],
      // 表单数据
      formData: {}
    }
  },
  computed: {
    // 服务id
    id () {
      return this.$route.params.id
    }
  },
  created () {
    this.getServiceDetail()
  },
  methods: {
    // 获取服务详情
    getServiceDetail () {
      api.getServiceDetail(this.id).then(res => {
        if (res.data.code === 0) {
          console.log('服务详情', res.data.data)
          this.fieldList = res.data.data.fields
          this.formData = res.data.data.data
          this.serviceTypeId = res.data.data.serviceTypeId
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },

    // 提交表单
    submitForm () {
      this.validateCustomForm()
    },
    // 验证自定义表单
    async validateCustomForm () {
      try {
        const data = await this.$refs.customForm.validate()
        const fixedData = {}
        const columnData = {}
        // 把固定字段表单项和非固定自定表单项值隔离开来
        this.fieldList.forEach(item => {
          if (item.isFixed) {
            fixedData[item.column] = data[item.column]
          } else {
            columnData[item.column] = data[item.column]
          }
        })
        // 创建服务所需要的数据
        const serviceData = {
          service: {
            serviceTypeId: this.serviceTypeId,
            ...fixedData
          },
          formData: {
            ...columnData
          }
        }
        this.updateService(serviceData)
      } catch (err) {
        console.log(err)
        return false
      }
    },
    // 编辑服务
    updateService (data) {
      this.btnLoading = true
      api.updateService(this.id, {
        ...data
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('编辑成功！')
          this.$router.replace('/resource-require/service')
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .detail_main {
    padding: 30px 0;
    .main_content {
      width: 560px;
      margin: 0 auto;

      .btn_box {
        padding: 30px 0 0 180px;
      }
    }
  }
}
</style>
